/**
 * @desc 基础数据 学生
 */
import { getStudentList, getGradeList, getClassListByGradeId, deleteStudent, batchDeleteStudent } from '@/api/basic';
import { Table, TableColumn, Input, Button, Select, Option, Checkbox } from 'element-ui';

import basicHeader from '@/components/backStageHeader';
import searchInput from '@/components/searchInput';
import qlPagination from '@/components/qlPagination';
import studentDialog from './components/studentDialog';
import passwordDialog from './components/passwordDialog';

import * as consts from './constance';

export default {
  name: 'basic-student',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Input.name]: Input,
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    [Checkbox.name]: Checkbox,
    basicHeader,
    searchInput,
    qlPagination,
    studentDialog,
    passwordDialog,
  },
  data() {
    return {
      grades: [
        {
          gradeId: '',
          gradeName: '全部',
        },
      ],
      classes: [
        {
          classId: '',
          className: '全部',
        },
      ],
      table: {
        list: [],
        total: 0,
        pageSize: 10,
        pageIndex: 1,
        gradeId: '',
        classId: '',
        keyword: '',
      },
      multipleSelection: [],
      selectAll: false,
      studentDialogData: {
        visible: false,
        optionType: consts.DIALOG_TYPE_ADD,
        studentId: '',
      },
      passwordDialogData: {
        visible: false,
        studentId: '',
        optionType: consts.PASSWORD_RESET_ONE,
        students: [],
      },
    };
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
    notAllowOperate() {
      return this.userInfo.thirdpartySchoolProjectCode !== '';
    },
  },
  methods: {
    // handle 搜索
    handleSearch() {
      this.table.pageIndex = 1;
      this.getStudentPageJSON();
    },
    // handle 切换年级
    handleChangeGrade(val) {
      this.table.gradeId = val;
      this.table.pageIndex = 1;
      this.selectAll = false;
      this.multipleSelection = [];
      this.$refs.table.clearSelection();

      if (val === consts.TAG_UNCLASSIFIED) {
        this.table.classId = consts.TAG_UNCLASSIFIED;
        this.classes = [
          {
            classId: consts.TAG_UNCLASSIFIED,
            className: '未归类',
          },
        ];
      } else {
        this.table.classId = '';

        getClassListByGradeId({
          gradeId: val,
        }).then(({ result }) => {
          this.classes = [
            {
              classId: '',
              className: '全部',
            },
            ...result,
          ];
        });
      }

      this.getStudentPageJSON();
    },
    // handle 切换班级
    handleChangeClass() {
      this.table.pageIndex = 1;
      this.selectAll = false;
      this.multipleSelection = [];
      this.$refs.table.clearSelection();
      this.getStudentPageJSON();
    },
    // handle 新增
    handleAdd() {
      if (this.notAllowOperate) return;
      this.studentDialogData.optionType = consts.DIALOG_TYPE_ADD;
      this.studentDialogData.visible = true;
    },
    // handle 编辑
    handleEdit(val) {
      if (this.notAllowOperate) return;
      this.studentDialogData.optionType = consts.DIALOG_TYPE_EDIT;
      this.studentDialogData.studentId = val.userId;
      this.studentDialogData.visible = true;
    },
    // handle 修改密码
    handleChangePassword(val) {
      if (this.notAllowOperate) return;
      this.passwordDialogData.optionType = consts.PASSWORD_RESET_ONE;
      this.passwordDialogData.students = [val];
      this.passwordDialogData.visible = true;
    },
    // handle 修改密码成功
    handlePasswordSuccess() {
      this.passwordDialogData.visible = false;
      this.selectAll = false;
      this.multipleSelection = [];
      this.$refs.table.clearSelection();
    },
    // handle 多选
    handleSelectionChange(val) {
      this.multipleSelection = val;
      if (val.length) {
        this.selectAll = false;
      }
    },
    // handle 全部
    handleSelectAll(val) {
      val && this.$refs.table.clearSelection();
    },
    // handle 批量删除
    handleBatchDelete() {
      if (this.notAllowOperate) return;

      this.$msgbox
        .confirm(`你确定禁用${this.multipleSelection.map((x) => x.userName).join(',')}吗?`, '提示', {
          customClass: 'basic-data-student-batch-delete-dialog',
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
        .then(() => {
          return batchDeleteStudent({
            studentIds: this.multipleSelection.map((x) => x.userId).toString(),
          }).then(() => {
            this.$message({
              type: 'success',
              message: `已禁用${this.multipleSelection.map((x) => x.userName).join(',')}!`,
            });
            this.selectAll = false;
            this.multipleSelection = [];
            this.$refs.table.clearSelection();
            this.getStudentPageJSON(1);
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作',
          });
        });
    },
    // handle 批量重置密码
    handleBatchReset() {
      if (this.notAllowOperate) return;
      this.passwordDialogData.optionType = this.selectAll ? consts.PASSWORD_RESET_ALL : consts.PASSWORD_RESET_BATCH;
      this.passwordDialogData.students = this.multipleSelection;
      this.passwordDialogData.visible = true;
    },
    // handle 删除
    handleDelect(val) {
      if (this.notAllowOperate) return;

      this.$msgbox
        .confirm(`你确定禁用${val.userName}吗?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
        .then(() => {
          deleteStudent({
            studentId: val.userId,
          }).then(() => {
            this.$message({
              type: 'success',
              message: `已删除${val.userName}!`,
            });
            this.getStudentPageJSON();
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作',
          });
        });
    },
    getStudentPageJSON(pageIndex) {
      let params = {
        keyword: this.table.keyword,
        pageSize: this.table.pageSize,
        pageIndex: pageIndex || this.table.pageIndex,
      };

      if (this.table.gradeId === consts.TAG_UNCLASSIFIED) {
        params.unclassifiedFlag = 0;
      } else {
        params.gradeId = this.table.gradeId;
        params.classId = this.table.classId;
        params.unclassifiedFlag = 1;
      }

      getStudentList(params).then(({ result }) => {
        if (result.data.length === 0 && this.table.pageIndex !== 1) {
          this.table.pageIndex--;
          this.getStudentPageJSON();
          return;
        }

        this.table.list = result.data;
        this.table.total = result.recordCount;
        this.table.pageIndex = result.pageIndex;
      });
    },
    initPage() {
      if (this.table.gradeId === consts.TAG_UNCLASSIFIED) {
        this.table.classId = consts.TAG_UNCLASSIFIED;
        this.classes = [
          {
            classId: consts.TAG_UNCLASSIFIED,
            className: '未归类',
          },
        ];
      }

      getGradeList().then(({ result }) => {
        this.grades = [
          ...this.grades,
          ...result,
          {
            gradeId: consts.TAG_UNCLASSIFIED,
            gradeName: '未归类',
          },
        ];
      });

      this.getStudentPageJSON();
    },

    // 批量录入
    handleImport() {
      if (this.notAllowOperate) return;

      this.$batchEntry({
        type: 'student',
        callback: () => {
          this.table.pageIndex = 1;
          this.getStudentPageJSON();
        },
      });
    },

    handleExport() {
      this.$batchExport({
        type: 'student',
      });
    },
  },
  created() {
    this.initPage();
  },
};
