<template>
  <el-dialog
    custom-class="ql-dialog "
    class="password-dialog"
    :title="dialogTitle"
    :visible.sync="dialogVisible"
    width="589px"
    v-loading="isLoading"
    element-loading-background="rgba(0, 0, 0, 0.1)"
    @close="handleCancel"
  >
    <div class="flex mg-b-40" style="margin-left: -16px" v-if="optionType !== PASSWORD_RESET_ONE">
      <svg class="fz-18 icon" aria-hidden="true">
        <use xlink:href="#icon-icon_jinggao"></use>
      </svg>
      <p class="flex-1 mg-l-8">你确定重置{{ studentNames }}密码?</p>
    </div>
    <el-form :model="form" :rules="rules" ref="passwordForm" label-width="72px">
      <el-form-item label="密码类型" prop="optionType" size="small">
        <el-radio-group v-model="form.optionType">
          <el-radio :label="TYPE_DEFAULT_PASSWORD">默认密码</el-radio>
          <el-radio :label="TYPE_NEW_PASSWORD">新密码</el-radio>
        </el-radio-group>
      </el-form-item>
      <div v-if="form.optionType === TYPE_DEFAULT_PASSWORD" class="flex" style="margin-top: -8px">
        <p>当学生有学号时，默认为qljy+学号后4位，不足4位则以0补位，例：qljy0001；当学生无学号时，默认为qljy0000</p>
      </div>
      <el-form-item v-if="form.optionType === TYPE_NEW_PASSWORD" label="新密码" prop="password" size="small">
        <el-input v-model="form.password" show-password></el-input>
        <div class="tip">8-24位，至少包含数字、字母或特殊字符中两种方式</div>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button size="small" @click="handleCancel">取 消</el-button>
      <el-button type="primary" size="small" @click="handleSave">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { saveStudentPassword, batchResetStudentPassword, resetStudentPassword } from '@/api/basic';

import { Dialog, Form, FormItem, Button, Input, RadioGroup, Radio } from 'element-ui';

import { checkPassword } from '@/utils/validate';
import { PASSWORD_RESET_ONE, PASSWORD_RESET_BATCH, PASSWORD_RESET_ALL, TAG_UNCLASSIFIED } from '../constance';

const TYPE_NEW_PASSWORD = '0';
const TYPE_DEFAULT_PASSWORD = '1';

export default {
  components: {
    [Dialog.name]: Dialog,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Button.name]: Button,
    [Input.name]: Input,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
    gradeId: String,
    classId: String,
    optionType: Number,
    students: Array,
  },
  computed: {
    dialogTitle() {
      return this.optionType === PASSWORD_RESET_ONE ? '修改学生密码' : '重置学生密码';
    },
    studentNames() {
      if (this.optionType === PASSWORD_RESET_ALL) {
        return '全部学生';
      } else {
        return this.students.map((x) => x.userName).join(', ');
      }
    },
  },
  data() {
    const validatePassword = (rule, value, callback) => {
      const res = checkPassword(value);

      if (res !== true) {
        callback(new Error(res));
        return;
      }

      callback();
    };

    return {
      TYPE_NEW_PASSWORD,
      TYPE_DEFAULT_PASSWORD,
      PASSWORD_RESET_ONE,

      isLoading: false,
      dialogVisible: false,
      form: {
        optionType: TYPE_DEFAULT_PASSWORD,
        password: '',
      },
      rules: {
        password: [{ validator: validatePassword, trigger: 'blur' }],
      },
    };
  },
  methods: {
    handleSave() {
      this.$refs.passwordForm.validate((valid) => {
        if (valid) {
          switch (this.optionType) {
            case PASSWORD_RESET_ONE:
              this.saveStudentPassword();
              break;
            case PASSWORD_RESET_BATCH:
              this.batchResetStudentPassword();
              break;
            case PASSWORD_RESET_ALL:
              this.resetStudentPassword();
              break;
            default:
              break;
          }
        } else {
          return false;
        }
      });
    },
    handleCancel() {
      this.$emit('close');
    },

    // 保存密码
    saveStudentPassword() {
      let data = {
        studentId: this.students.map((x) => x.userId).join(','),
        optionType: this.form.optionType,
      };
      if (this.form.optionType === TYPE_NEW_PASSWORD) {
        data.password = this.form.password;
      }
      saveStudentPassword(data).then(() => {
        this.$message.success(`修改密码成功`);
        this.$emit('success');
      });
    },

    // 批量重置密码
    batchResetStudentPassword() {
      let data = {
        userIds: this.students.map((x) => x.userId).join(','),
        userType: 1, // 用户类型(1学生，4教师)
      };
      if (this.form.optionType === TYPE_NEW_PASSWORD) {
        data.pwd = this.form.password;
      }

      batchResetStudentPassword(data).then(() => {
        this.handleSuccess();
      });
    },

    // 重置全部学生密码
    resetStudentPassword() {
      this.$msgbox
        .confirm('你确定重置全部学生密码？', '提示', { type: 'warning' })
        .then(() => {
          this.isLoading = true;
          let data = {};
          if (this.gradeId === TAG_UNCLASSIFIED) {
            // 未归类
            data.unclassifiedFlag = 0;
          } else {
            data.gradeId = this.gradeId;
            data.classId = this.classId;
            data.unclassifiedFlag = 1;
          }

          if (this.form.optionType === TYPE_NEW_PASSWORD) {
            data.pwd = this.form.password;
          }

          resetStudentPassword(data)
            .then(() => {
              this.handleSuccess();
            })
            .finally(() => {
              this.isLoading = false;
            });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作',
          });
        });
    },

    handleSuccess() {
      if (this.form.optionType === TYPE_NEW_PASSWORD) {
        this.$message.success(`已重置${this.studentNames}密码为${this.form.password}!`);
      } else {
        this.$message.success(`已重置${this.studentNames}密码!`);
      }
      this.$emit('success');
    },
  },
  watch: {
    visible: {
      handler: function (val) {
        Object.assign(this.$data, this.$options.data()); // reset
        this.dialogVisible = val;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.password-dialog {
  .tip {
    font-size: 12px;
    line-height: 1.5;
    margin-top: 5px;
    color: #b9b9b9;
  }
}
</style>
