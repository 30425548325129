<template>
  <el-dialog
    custom-class="ql-dialog "
    :title="typeWord + '学生'"
    :visible.sync="dialogVisible"
    width="600px"
    @close="handleCancel"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="55px">
      <el-form-item label="姓名" prop="userName" size="small">
        <el-input v-model="form.userName" :style="{ width: '100%' }" placeholder="请输入学生姓名"></el-input>
      </el-form-item>
      <el-form-item label="学号" prop="studentNo" size="small">
        <el-input v-model="form.studentNo" :style="{ width: '100%' }" placeholder="请输入学生学号"></el-input>
      </el-form-item>
      <el-form-item label="性别" prop="sex" size="small">
        <el-radio-group v-model="form.sex">
          <el-radio :label="'男'" @click.native.prevent="handleRadio('男')">男</el-radio>
          <el-radio :label="'女'" @click.native.prevent="handleRadio('女')">女</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="optionType === DIALOG_TYPE_ADD" label="密码" prop="password" size="small">
        <el-input v-model="form.password" :style="{ width: '100%' }" placeholder="请输入密码"></el-input>
        <div class="tips">
          <p class="tips-content">8-24位，至少包含数字、字母或特殊字符中两种方式</p>
          <p class="tips-content">
            若不填写密码，当学生有学号时，默认为qljy+学号后4位，不足4位则以0补位，例：qljy0001；当学生无学号时，默认为qljy0000
          </p>
        </div>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="handleCancel" size="small">取 消</el-button>
      <el-button type="primary" @click="handleSave" size="small">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { Form, FormItem, Dialog, Button, Input, RadioGroup, Radio, Select, Option } from 'element-ui';
import { getStudentInfo, addStudent, editStudent } from '@/api/basic';
import { checkQuote, checkPassword } from '@/utils/validate';
import { DIALOG_TYPE_ADD, DIALOG_TYPE_EDIT } from '../constance';

export default {
  name: 'StudentDialog',
  components: {
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    [Input.name]: Input,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
    optionType: {
      type: Number,
      required: true,
      default: DIALOG_TYPE_ADD,
    },
    studentId: {
      type: String,
      required: false,
    },
  },
  data() {
    const validateUserName = (rule, value, callback) => {
      if (value.trim().length === 0) {
        callback(new Error('姓名不能为空'));
        return;
      }
      if (value.trim().length > 15) {
        callback(new Error('姓名过长'));
        return;
      }
      if (!checkQuote(value)) {
        callback(new Error('姓名不能包含特殊字符'));
        return;
      }
      callback();
    };
    const validateStudentNo = (rule, value, callback) => {
      if (value === '') {
        callback();
        return;
      }
      if (value.trim().length > 25) {
        callback(new Error('学号过长'));
        return;
      }
      callback();
    };
    const validatePassword = (rule, value, callback) => {
      if (value === '') {
        callback();
        return;
      }
      const res = checkPassword(value);
      if (res !== true) {
        callback(new Error(res));
        return;
      }
      callback();
    };

    return {
      dialogVisible: false,

      form: {
        userName: '',
        studentNo: '',
        sex: '',
        password: '',
      },

      rules: {
        userName: [
          { required: true, message: '姓名不能为空', trigger: 'blur' },
          { validator: validateUserName, trigger: 'blur' },
        ],
        studentNo: [{ validator: validateStudentNo, trigger: 'blur' }],
        password: [{ validator: validatePassword, trigger: 'blur' }],
      },
      DIALOG_TYPE_ADD,
      DIALOG_TYPE_EDIT,
    };
  },
  computed: {
    typeWord() {
      switch (this.optionType) {
        case DIALOG_TYPE_ADD:
          return '新增';
        case DIALOG_TYPE_EDIT:
          return '编辑';
        default:
          return '';
      }
    },
  },
  methods: {
    handleRadio(val) {
      if (this.form.sex === val) {
        this.form.sex = '';
      } else {
        this.form.sex = val;
      }
    },
    handleSave() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const params = {
            studentNo: this.form.studentNo,
            userName: this.form.userName,
            sex: this.form.sex,
            password: this.form.password,
          };

          if (this.optionType === DIALOG_TYPE_EDIT) {
            params.userId = this.studentId;
            editStudent(params).then(() => {
              // console.log(res);
              this.$message.success(`编辑学生成功`);
              this.$emit('success');
            });
          } else {
            addStudent(params).then(() => {
              // console.log(res);
              this.$message.success(`新增学生成功`);
              this.$emit('success');
            });
          }
        } else {
          return false;
        }
      });
    },
    handleCancel() {
      this.$emit('close');
    },
  },
  watch: {
    visible: {
      handler: function (val) {
        if (val) {
          // reset
          Object.assign(this.$data, this.$options.data());

          this.$nextTick(() => {
            this.$refs.form.clearValidate();
          });

          // 编辑
          if (this.optionType === DIALOG_TYPE_EDIT && this.studentId) {
            getStudentInfo({
              userId: this.studentId,
            }).then(({ result }) => {
              this.form = Object.assign(this.form, result);
            });
          }
        }
        this.dialogVisible = val;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.tips {
  margin-top: 8px;
  padding: 8px 12px;
  font-size: 12px;
  color: #808080;
  line-height: 22px;
  background-color: #f8f8f8;
  border-radius: 4px;
}
</style>
